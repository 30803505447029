<template>
  <card-tooltip-wrapper :options="card">
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col
          v-for="(field, index) in fields"
          :key="index"
          :xs="field.format.xs"
          :sm="field.format.sm"
          :md="field.format.md"
          :lg="field.format.lg"
          :xl="field.format.xl"
        >
          <div>
            <!--
            {{ field  }}
-->
          </div>
          <validation-provider
            v-if="field.show === undefined ? true : field.show"
            #default="{ errors }"
            :name="field.label"
            :rules="field.rules"
          >
            <b-form-group
              :label="field.label"
              :label-for="field.id"
              :description="field.description"
            >
              <b-form-input
                v-if="field.type == 'input'"
                :id="field.id"
                :disabled="field.disabled || !editable"
                :state="errors.length > 0 ? false : null"
                v-b-tooltip.hover.top="field.tooltip"
                :value="formatInputValue(field.value)"
                @input="(value) => formInputChange(value, field)"
              ></b-form-input>

              <b-form-textarea
                v-if="field.type == 'textarea'"
                :id="field.id"
                :disabled="field.disabled || !editable"
                :state="errors.length > 0 ? false : null"
                rows="5"
                max-rows="10"
                :value="field.value"
                @input="(value) => formTextareaChange(value, field)"
              ></b-form-textarea>

              <b-form-select
                v-if="field.type == 'select'"
                :id="field.id"
                :options="field.options"
                :disabled="field.disabled || !editable"
                :state="errors.length > 0 ? false : null"
                :value="field.value"
                @input="(value) => formSelectChange(value, field)"
              ></b-form-select>

              <b-form-checkbox
                v-if="field.type == 'checkbox'"
                :id="field.id"
                :options="field.options"
                :disabled="field.disabled || !editable"
                :state="errors.length > 0 ? false : null"
                :checked="field.value"
                @input="(value) => formCheckboxChange(value, field)"
              ></b-form-checkbox>

              <b-form-datepicker
                v-if="field.type == 'datepicker'"
                :date-format-options=datePickerFormatOptions
                :locale=datePickerLocale
                :id="field.id"
                placeholder="Seleccione fecha"
                :disabled="field.disabled || !editable"
                :state="errors.length > 0 ? false : null"
                :value="field.value"
                :min="field.min"
                :max="field.max"
                @input="(value) => formDatepickerChange(value, field)"
              ></b-form-datepicker>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>

    <template v-slot:footer>
      <slot name="footer"> </slot>
    </template>
  </card-tooltip-wrapper>
</template>

<script>
const axios = require("axios");

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import
{
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length, numeric, decimal, numeric2
} from '@validations'

import CardTooltipWrapper from './wrappers/CardTooltipWrapper.vue';


import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";

export default {

  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BFormDatepicker,
    BCardTitle,
    BCardSubTitle,
    ValidationProvider,
    ValidationObserver,
    VBTooltip,
    VBPopover,

    CardTooltipWrapper

  },
  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  created()
  {
    localize('es')
  },

  methods: {

    formatInputValue(value)
    {

      //console.log("formatInputValue", value)

      try
      {

        if (isNaN(value))
        {
          return value;
        }

        let num = parseFloat(value)

        if (isNaN(num))
        {
          return value;
        }

        return Math.round(num * 100) / 100;


      } catch (error)
      {

        return value;

      }
    },

    formCheckboxChange(value, field)
    {
      field.value = value;

      this.$emit('change', field);

    },

    formTextareaChange(value, field)
    {
      field.value = value;

      this.$emit('change', field);
    },

    /*
    formInputChange(value, field)
    {
      field.value = value;

      this.$emit('change', field);
    },
*/
    formInputChange(value, field)
    {
      //console.log("formInputChange", {value, field})

      if(field.rules != undefined && field.rules.includes('numeric'))
      {
        if (typeof value === 'string' || value instanceof String)
        {
          value = value.trim();

          if(value == "")
          {
            field.value =  value;
          }
          else
          {
            if (isNaN(value))
            {
              field.value =  value;
            }
            else
            {
              field.value = parseFloat(value)
            }

          }


        }
        else
        {
          field.value =  value;
        }

      }
      else
      {
        field.value = value;
      }

      this.$emit('change', field);
    },


    formDatepickerChange(value, field)
    {
      field.value = value;


      this.$emit('change', field);
    },

    formSelectChange(value, field)  
    {
      field.value = value;

      this.$emit('change', field);



    },

    validate()
    {
      return this.$refs.simpleRules.validate()
    }

  },

  computed: {

  },

  mounted()
  {

  },

  beforeDestroy()
  {
    //console.log("beforeDestroy 2");
  },

  data()
  {

    let form = {};

    this.fields.forEach(field =>
    {
      form[field.id] = null
    });


    return {

      locale: 'es',
      //form: form

    };
  },

  props: {

    card: {
      type: Object,
      default: () => ({
        title: "Titulo",
        subtitle: "Subtitulo"
      })
    },

    fields: {
      type: Array,
      default: () => [

      ]

    },

    editable: {
      type: Boolean,
      required: false,
      default: true
    },

    datePickerFormatOptions: {
      type: Object,
      required: false,
      default: () => ({
        'year': 'numeric',
        'month': 'long',
        'day': 'numeric',
        'weekday': 'long'
      })
    },

    datePickerLocale: {
      type: String,
      required: false,
      default: 'en-US'
    },
  },
};
</script>