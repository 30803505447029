<template>
    <b-card class="text-center">
        
      <b-avatar
        class="mb-1"
        :variant="variant"
        size="45"
        :badge="badge"
        :badge-variant="badgeVariant"
        badge-top
        badge-offset="-1.0em"
      >
        <feather-icon size="21" :icon="icon" />
      </b-avatar>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ value }}
        </h2>
        <span>{{ name }} {{ unit }}</span>
  
        
      </div>
    </b-card>
  </template>
        
        <script>
  import
  {
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCard,
    VBPopover,
    BAvatar,
  
  } from "bootstrap-vue";
  
  const axios = require("axios");
  
  import VueSlider from 'vue-slider-component'
  
  
  export default {
    components: {
      BRow,
      BCol,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
      BCard,
      BAvatar
  
    },
  
    directives: {
    },
  
    data: function ()
    {
      return {
        badge: false,
        badgeVariant: 'primary',

  
      };
    },
    created()
    {
  
    },
   
    mounted()
    {
  
    },
  
    methods: {
  
      roundValue(value, decimales = 2)
      {
        let tmp = Math.pow(10, decimales)
  
         return Math.round(value * tmp) / tmp
      }
  
  
  
    },
  
  
  
    watch: {
      // whenever question changes, this function will run
      value: {
        handler(newValue, oldValue)
        {
  
          
  
        },
  
      }
    },
  
  
    props: {
  
      variant: {
        type: String,
        required: true
      },
  
      icon: {
        type: String,
        required: true
      },
  
      img: {
        type: String,
      },

      name: {
        type: String,
        required: true
      },
  
      unit: {
        type: String,
        required: true
      },
  
      value: {
        type: Number | String,
        required: true
      },


    },
  
  };
        </script>
        
        <style lang="scss"></style>
        